import { Skeleton } from '@ui'

export const PackageCardSkeleton = () => {
	return (
		<Skeleton
			maxWidth="362px"
			width="full"
			height="314px"
			rounded="lg"
		/>
	)
}
