export enum DictionaryTypes {
  TicketClassDictionary = 'TicketClassDictionary',
  FoodTypeDictionary = 'FoodTypeDictionary',
  TransferTypeDictionary = 'TransferTypeDictionary',
  RoomTypeDictionary = 'RoomTypeDictionary',
  RequestStatusDictionary = 'RequestStatusDictionary',
  FacilityDictionary = 'FacilityDictionary',
}

export enum TermsAndConditionTypes {
  bookTerms = 'bookTerms',
  cancelTerms = 'cancelTerms',
}
