import './index.scss'
import React from 'react'

const PackagesHeader = () => (
	<div className="header text-center font-bold background">
		<div className="container">
			<img className="packages-logo" src="/images/logo.svg" alt=""/>
			<div className="top-title">Սկսիր պլանավորել հանգիստդ հիմա</div>
			<div className="sub-title inline-block">
				Ավիատոմսից մինչև հյուրանոց բոլոր ամրագրումներն ու վճարումները մի տեղում
			</div>
		</div>
	</div>
)

export default PackagesHeader
