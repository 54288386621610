import { RecoilRoot } from 'recoil'
import { BrowserRouter } from 'react-router-dom'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { QueryProvider, ThemeProvider } from './providers'

import Routes from './Routes.tsx'
import '../App.css'
import Toaster from '../components/Toaster/Toaster.tsx'
import { PackagesSearchProvider } from '@entities/package'

function App() {
	return (
		<GoogleOAuthProvider clientId="174037716864-g19hqju9dqo4oked1ecb9cg1antitca5.apps.googleusercontent.com">
			<RecoilRoot>
				<QueryProvider>
					<ThemeProvider>
						<BrowserRouter>
							<PackagesSearchProvider>
								<Routes/>
							</PackagesSearchProvider>
							<Toaster/>
						</BrowserRouter>
					</ThemeProvider>
				</QueryProvider>
			</RecoilRoot>
		</GoogleOAuthProvider>
	)
}

export default App
