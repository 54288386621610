import { Language } from './types'

export const LANGUAGES: Language[] = [
	{
		name: 'arm',
		label: 'Հայերեն',
		icon: 'flag-arm'
	},
	{
		name: 'rus',
		label: 'Русский',
		icon: 'flag-rus'
	},
	{
		name: 'eng',
		label: 'English',
		icon: 'flag-eng'
	},
]