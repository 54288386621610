export enum PackagesFields {
  nameArm = 'nameArm',
  nameEng = 'nameEng',
  nameRus = 'nameRus',
  descriptionArm = 'descriptionArm',
  descriptionEng = 'descriptionEng',
  descriptionRus = 'descriptionRus',
  bookingPolicyArm = 'bookingPolicyArm',
  bookingPolicyRus = 'bookingPolicyRus',
  bookingPolicyEng = 'bookingPolicyEng',
  cancelationPolicyArm = 'cancelationPolicyArm',
  cancelationPolicyRus = 'cancelationPolicyRus',
  cancelationPolicyEng = 'cancelationPolicyEng',
  offerId = 'offerId',
  city = 'city',
  adultTravelers = 'adultTravelers',
  childrenTravelers = 'childrenTravelers',
  nights = 'nights',
  price = 'price',
  id = 'id',
  hotel = 'hotel',
  images = 'images',
  url = 'url',
  stars = 'stars',
  foodType = 'foodType',
  roomType = 'roomType',
  checkin = 'checkin',
  checkout = 'checkout',
  destinationFlight = 'destinationFlight',
  departureDate = 'departureDate',
  arrivalDate = 'arrivalDate',
  returnFlight = 'returnFlight',
  airCompany = 'airCompany',
  name = 'name',
  ticketClass = 'ticketClass',
  flightType = 'flightType',
  fLightCode = 'fLightCode',
  travelAgency = 'travelAgency',
  adults = 'adults',
  childs = 'childs',
  lateCheckout = 'lateCheckout',
  cleanliness = 'cleanliness',
  travellersRating = 'travellersRating',
  facilities = 'facilities',
  hotelId = 'hotelId',
  flightId = 'flightId',
  returnFlightId = 'returnFlightId',
  firstName = 'firstName',
  lastName = 'lastName',
  birthDate = 'birthDate',
  dateOfBirth = 'dateOfBirth',
  childMaxAge = 'childMaxAge',
  amountToBePaid = 'amountToBePaid',
  usdRate = 'usdRate',
  size = 'size',
}

export enum PackagesNestedFields {
  country = 'country',
}

export enum CustomFields {
  destinationFlightDate = 'destinationFlightDate',
  returnFlightDate = 'returnFlightDate',
  childrenUnderTwoYears = 'childrenUnderTwoYears',
  childrenOverTwoYears = 'childrenOverTwoYears',
  email = 'email',
  phoneNumber = 'phoneNumber',
  payFullPrice = 'payFullPrice',
  cityId = 'cityId',
  startDate = 'startDate',
  endDate = 'endDate',
  travelAgencyId = 'travelAgencyId',
  notes = 'notes',
  travelers = 'travelers',
  destinationFlightId = 'destinationFlightId',
  returnFlightId = 'returnFlightId',
  hotelId = 'hotelId',
}

export enum DictionaryFields {
  key = 'key',
  value = 'value',
}
