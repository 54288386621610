export const colorsTheme = {
	colors: {
		blue: {
			50: '#E7F0FF',
			100: '#CEE1FF',
			200: '#9DC3FF',
			300: '#5496FF',
			400: '#3C87FF',
			500: '#0B69FF',
			600: '#0050E5',
			700: '#0036CC',
			800: '#001DB2',
			900: '#00004D'
		},
		green: {
			500: '#05CA6C',
			600: '#00AE4E'
		},
		red: {
			500: '#FF254C',
			600: '#E50B32',
			700: '#B20000'
		},
		orange: {
			50: '#FFF4E9',
			500: '#FF8F27'
		}
	}
}
