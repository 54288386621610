export const SOCIALS = [
	{
		icon: 'instagram',
		link: 'https://www.instagram.com/mytour.am/'
	},
	{
		icon: 'facebook',
		link: 'https://www.facebook.com/profile.php?id=61558534410193'
	},
	{
		icon: 'linkedin',
		link: 'https://www.linkedin.com/company/104319450/admin/dashboard/ '
	},
]